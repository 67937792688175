<template>
<!-- Sidebar Respuesta rapida-->
<div id="sidebar-wrapper" class="">

    <div class="contEspecial">
        <div v-if="sender" class="sticky22">
            <div v-show="network=='WA'" @click="abrirPaciente" class="abrirOpc" id="menu-toggle2">
                <img id="img-tresPuntos" src="../assets/images/menu.svg" alt="" />
            </div>
            <div v-show="network=='WA'" @click="abrirInfo" class="abrirOpc" id="menu-toggle3">
                <font-awesome-icon icon="copy" />
            </div>
            <!-- <div v-show="network=='WA'" @click="abrirPDF" class="abrirRSV" id="menu-toggle">
                <font-awesome-icon icon="file-pdf" />
            </div> -->
        </div>

        <button v-if="sender" @click="cerrar" type="button" class="close tacha">
            <span aria-hidden="true">&times;</span>
        </button>
        
        <!-- PDF -->
        <ul v-if="this.res==1" id="pdf" class="ulHerramientas">
                <pdf/>   
        </ul>
        <ul v-else-if="this.res==2" id="paciente" class="ulHerramientas">
                <opciones/>
        </ul>

        <ul v-else-if="this.res==3" class="ulHerramientas">
            <div class="scroll">
                <li v-for="rRapida in mensajes" :key="rRapida.id" class="respuestaRapida ml-1 row">
                    
                    <button @click="responder(rRapida.id)" class="btnEnviar float-left col-1">
                        <font-awesome-icon icon="share" flip="horizontal" />
                    </button>
                    <h5 class="font-weight-bold">{{rRapida.nombre}}</h5>
                    <p class="col-11 mb-0" v-if="rRapida.tipo_msj == 'TEXT'">{{rRapida.msj}}</p>
                    <div class="card" v-else-if="rRapida.tipo_msj == 'IMG'">
                        <img class="card-img-top imagenCardSidebar align-self-center" :src="rRapida.msj" alt="Img de Card">
                        <div class="card-body"> {{rRapida.caption}} </div>
                    </div>
                    <p class="col-11 mb-0" v-else>{{rRapida.msj}} <b>{{rRapida.caption}}</b></p>
                
                </li>
            </div>
            
        </ul>
    </div>
</div> <!-- /#sidebar-wrapper Respuesta rapida-->
</template>

<script>
import asesorService from '../services/asesor'
import PDF from './Pestañas/PDF.vue'
import Opciones from './Pestañas/Opciones.vue'
//import Sars from './Pestañas/Sars.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faShare, faRobot, faFile, faCoins, faMapMarkedAlt, faFileMedicalAlt, faFilePdf, faVirus,faRibbon, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCopy, faShare, faRobot, faFile, faCoins, faMapMarkedAlt, faFileMedicalAlt, faFilePdf, faVirus,faRibbon, faHandHoldingUsd)
export default {
    name: 'HerramientasAsesor',
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'pdf': PDF,
        'opciones': Opciones,
    },
    data() {
        return {
            buscador: '',
            search: '',
            res: 8,
            network: null,
            sender: false,
            imagenes: [
                {
                    tema: 'Tutorial X',
                    coleccion: [
                        {
                            imagen: 'https://www.examencei.com.mx/wp-content/uploads/2017/01/shutterstock_462376603-900x450.jpg',
                            text: 'Primer paso, darle *click* aqui'
                        },
                        {
                            imagen: 'https://lh3.googleusercontent.com/proxy/Mr2vD1pT3IqRymHa2Bmr_NY9L0WRspjlRqkTy7ULhOo5rNImebCBf1A6qvkJDbYi7rmo_I5ggysxupL2gNBQZaQ3SvHMwV1uy3LGrUF-jMyRTUY',
                            text: 'Segundo paso, darle *click* aqui'
                        },
                    ]
                },
                {
                    tema: 'Tutorial N',
                    coleccion: [
                        {
                            imagen: 'https://miposicionamientoweb.es/wp-content/uploads/2019/07/tutorial-de-Google-Search-Console-webmaster-tools.png',
                            text: 'Todo está bien'
                        },
                    ]
                },
            ],
            mensajes:null,
            mensajesLucha:null
        }
    },
    created() {   
        this.$bus.$off('respuestas_rapidas')
        this.$bus.$on('respuestas_rapidas', text => {
            this.respuestas(text)
        })
        this.$bus.$off('existeSender')
        this.$bus.$on('existeSender', sender => {
            this.sender=sender
            this.networkActiva()
        }),
        this.respuestas()
    },
    methods: {
        respuestas(){
            asesorService.getInfo().then((respuestas) => { 
                this.mensajes = respuestas
             })
        },
        networkActiva(){
            this.network = asesorService.getNetwork()
        },
        cerrar(activo){
            this.res=0
            this.$emit('emitToggled', activo)
        },
        abrirPDF() {
            this.res=1
            this.$emit('Toggled')
        },   
        abrirPaciente() {
            this.res=2
            this.$emit('Toggled')
            this.$bus.$emit('paciente')
        },
        abrirInfo(){
            this.res=3
            this.$emit('Toggled')
        }, 
        responder(id){
            asesorService.InfoEnviar(id).then(() => { 
                
             })
        }
    }
}
</script>
