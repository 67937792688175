<template>
    <div>
        <div class="chatsTitle">
            <span v-if="!bandera" class="titulo">Chats activos</span><span v-if="!bandera" class="badge" :class="inbox>0 ? 'chatBadgeA' : ''">{{inbox}}</span>
            <span v-if="bandera" class="titulo">Chats pendientes</span><span v-if="bandera" class="badge secondary">{{espera}}</span>
        </div>
        <div class="chatsContenedor">
            <div class="sinChats" v-if="!sinChats() && !bandera">
                <img src="../assets/images/sin-chats.svg" alt="" />
                <span>No hay chats activos</span>
            </div>
            <div class="sinChats pendientes" v-if="!pendientes() && bandera">
                <img src="../assets/images/sin-pendientes.svg" alt="" />
                <span>No hay chats pendientes</span>
            </div>
            <div v-for="(chat, i) in chats" :key="chat.id">
                <div v-show="(((buscar == '') || (chat.nombre.includes(buscar)) || (chat.senderid.includes(buscar) )) && (bandera ? chat.espera : !chat.espera))" @click="activo(i,chat.senderid, chat.session,chat)" :id="i" class="cardChats" :href="'#'+ chat.senderid" :aria-controls="chat.senderid" role="button">
                    <div class="pildora">               
                        <div v-show="chat.tags.queja" class="eQueja" title="Queja">
                        </div>
                        <div v-show="chat.tags.atendido" class="eAtendido" title="Atendido">
                        </div>
                        <div v-show="chat.tags.credito" class="eConsulta" title="Credito">
                        </div>
                        <div v-show="chat.tags.ventas" class="eRH" title="Ventas">
                        </div>
        
                    </div>
                    <div class="listas">
                        <div class="circulo d-flex flex-wrap align-content-center" :class="{'bg-whatsapp': network == 'WA'||'WAni', 'bg-messenger': network == 'FB'}">
                            <p class="wa" v-if="network == 'WA'">       
                                <i><font-awesome-icon :icon="['fab', 'whatsapp']" size="2x"/></i> 
                            </p>
                            <p v-else class="iconoChat">
                                <i class="icon-messenger-new opt-fb"><font-awesome-icon :icon="['fab', 'facebook-messenger']" size="1x"/></i>                    </p>
                        </div>
                        <span :id="'visto'+i" class="badge nuevosMensajes chatBadge" :class="{'tiempoChat':((chat.diferencia>3)&&(chat.origen == '2')), 'badge-asesor': chat.origen == 3, 'badge-bot': chat.origen == '1', 'badge-paciente': ((chat.diferencia<3)&&(chat.origen == '2')), 'badge-plataforma': chat.origen == '4', 'badge-warning': chat.origen == '5'}">
                            <p v-if="chat.origen == 1">Bot</p>
                            <p v-else-if="((chat.diferencia>3)&&(chat.origen == 2))">{{Math.round(chat.diferencia)}} min</p>
        
                            <p v-else-if="chat.origen == 3">Asesor</p>
                            <p v-else-if="chat.origen == 4">Nuevo</p>
                            <p v-else-if="chat.origen == 5">Traspaso</p>
                            <p v-else>Asignado</p>
                        </span>
                        <div id="eleccionChat" role="tablist" @click="menuChat(chat.senderid)">
                            <opciones-chat :espera="chat.espera" :tags="chat.tags" :sender="chat.senderid" />
                            <p class="nombreChat"><strong>{{chat.nombre}}</strong>
                                <br><span v-if="network == 'FB'|| network == 'FBni'">Nos contactó por Messenger</span><span v-else-if="network == 'WA'||'WAni'">{{formatNumber(chat.numero)}}</span>
                            </p>
                            <div class="message">
                                <span v-if="chat.origen_tipo == 'INICIO_TEXT'">Ha ingresado un nuevo cliente</span>
                                <span v-else-if="((chat.origen_tipo == 'USER_TEXT')||(chat.origen_tipo == 'ASESOR_TEXT')||(chat.origen_tipo=='ASESOR_MSJ'))" class="text-truncate">{{chat.ultimoMsj}}</span>
                                <span v-else-if="chat.origen_tipo == 'USER_IMAGE'" class="text-truncate">Imagen <font-awesome-icon icon="image" /></span>
                                <span v-else-if="chat.origen_tipo == 'USER_DOCUMENT'" class="text-truncate">Archivo <font-awesome-icon icon="file-alt" /></span>
                                <span v-else-if="chat.origen_tipo == 'USER_VIDEO'" class="text-truncate">Video <font-awesome-icon icon="film" /></span>
                                <span v-else-if="chat.origen_tipo == 'USER_AUDIO'" class="text-truncate">Audio <font-awesome-icon icon="file-audio" /></span>
                                <span v-else-if="chat.origen_tipo == 'USER_LOCATION'" class="text-truncate">Ubicación <font-awesome-icon icon="map-marker-alt" /></span>
                                <span v-else-if="chat.origen_tipo == 'USER_CONTACTS'" class="text-truncate">Contacto <font-awesome-icon icon="address-book" /></span>
                                <span v-else-if="chat.origen_tipo == 'TRASPASO_TRASPASO'" class="text-truncate">Traspaso <font-awesome-icon icon="people-arrows" /></span>
                                <span v-else-if="chat.origen_tipo == 'REGRESO_COLA'" class="text-truncate">El cliente regresó de la cola</span>
                                <span v-else-if="chat.tipoMsj == 9" class="text-truncate">Mensaje no legible<font-awesome-icon icon="times" /></span>
                                <span v-else class="text-truncate">Mensaje desconocido</span>
                            </div>
                        </div>
        
                    </div>
        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OpcionesChat from './OpcionesChat'
import asesorService  from '../services/asesor'
//import SocketIo from 'socket.io-client'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faComment,
    faImage,
    faFileAudio,
    faFilm,
    faMapMarkerAlt,
    faFileAlt, 
    faAddressBook,
    faPeopleArrows, 
    faGlobeAmericas
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faComment, faImage, faFileAudio, faFilm, faMapMarkerAlt, faFileAlt, faAddressBook,faPeopleArrows, faGlobeAmericas)
export default {
    name: 'Chat',
    components: {
        OpcionesChat,
        'font-awesome-icon': FontAwesomeIcon
    },
    props: {
        buscar: String,
        etiqueta:null
    },
    data() {
        return {
            network: localStorage.getItem('Network'),
            eUbicacion: this.$abrircerrar,
            arregloTotal: 0,
            chats: '',
            mensajes: '',
            bandera: false,
            polling: null,
            hiloGet: null,
            espera: 0,
            inbox: 0,
            actualizado:0,
            sender_actual:sessionStorage.getItem('sender_actual'),
            sender_actualID:sessionStorage.getItem('seleccionChat'),
            asesorId: JSON.parse(sessionStorage.getItem('sesion')),
            cola: null
        }
    },
    created() {
        this.$bus.$off('actualiza')
        this.$bus.$on('actualiza', async () => {
            await asesorService.pedirPaciente()
            this.get()
            this.$bus.$emit('pacienteencola', 1)
        })
        this.$bus.$off('actualizar-pacientes')
        this.$bus.$on('actualizar-pacientes', () => {
            let sender = sessionStorage.getItem('sender')
            asesorService.finalizarPaciente(sender).then(() => {
                this.get()
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
            })
        })
        
        this.$bus.$off('finalizar-pacientes')
        this.$bus.$on('finalizar-pacientes', () => {
            let sender = sessionStorage.getItem('sender')
            asesorService.finalizarPacienteEncuesta(sender).then(() => {
                this.get()
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
            })
        })
        this.$bus.$off('despedida-programada')
        this.$bus.$on('despedida-programada', () => {
            let sender = sessionStorage.getItem('sender')
            asesorService.finalizacionProgramada(sender).then(() => {
                this.get()
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
            })
        })
        this.$bus.$off('finalizar-px-sinsesion')
        this.$bus.$on('finalizar-px-sinsesion', () => {
            let sender = sessionStorage.getItem('sender')
            asesorService.mandarEspera(sender).then(() => {
                this.get()
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
            })
        })
        this.$bus.$off('actualizar-pacientes_transf')
        this.$bus.$on('actualizar-pacientes_transf', () => {
            this.get()
            sessionStorage.getItem('sender_actual')
        })
        this.$bus.$off('espera')
        this.$bus.$on('espera', () => {
            this.get()
            this.bandera = true
        })
        this.$bus.$off('inbox')
        this.$bus.$on('inbox', () => {
            this.get()
            this.bandera = false
        })
        this.$bus.$off('pedirListaChats')
        this.$bus.$on('pedirListaChats', async () => {
            this.get()
        })
        this.get().then(() => {
            this.activar(this.sender_actualID, this.sender_actual)
        })

        
    },
    mounted(){
        // if(this.actualizado==0){
        //     if(this.sender_actual!=null){
        //         this.activar(this.sender_actualID, this.sender_actual)
        //     }
        //     this.actualizado=1
        // }
    },
    methods: {
        get() {
            
            // let data = {
            // query: {
            //     "Tipo": "2",
            //     "asesorId": this.asesorId.id
            //     }
            // }
            // var socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
            // socket.on('pacientesAtendidosInbox', (chat) => {
            //     this.espera=0
            //     this.inbox=0

            //     chat.map(chat =>{
            //         if(chat.senderid.substr(0,3) == '521'){
            //             chat.numero = chat.senderid.substr(3,10)
            //         } else {
            //             chat.numero = chat.senderid
            //         }
            //         if(chat.espera==true){
            //             this.espera++
            //         } else {
            //             this.inbox++
            //         }                  
            //         return chat
            //     })

            //     this.chats = chat
                
            //     this.arregloTotal = this.chats.length
            //     this.$bus.$emit('chatsTotal', this.arregloTotal)
            //     this.$bus.$emit('espera1', this.espera)
            //     this.$bus.$emit('inbox1', this.inbox)
            // })

            // if (this.sender_actual!=null){
            //     var chatActivo = document.getElementById(this.sender_actualID)
            //     chatActivo.classList.add('chatActive')
            //     this.vermensajes(this.sender_actual)
            //     this.sendmensaje(this.sender_actual)
            // }else{
            //     this.$bus.$emit('cerrarBarra', true)
            // }




            this.network=localStorage.getItem('Network')
            clearTimeout(this.hiloGet)
            return asesorService.pacientesAtendiendo().then(respa => {
                var moment = require('moment')
                moment.locale('es')
                this.espera=0
                this.inbox=0
                console.log(respa)
                // const arrEtiquetas=[
                //     'queja',
                //     'atendido',
                //     'credito',
                //     'ventas'
                // ]
                respa = respa.map(chat =>{
                    // console.log('entre')

                    if(chat.etiquetas!=null){
                        // console.log('sali')
                        const regex = /'/gi;
                        let cad = chat.etiquetas.replace(regex,`"`)
                        if(this.IsJsonString(cad))
                            chat.tags= JSON.parse(cad) || null
                        else
                            chat.tags={"queja":false, "atendido": false, "credito":false, "ventas":false,"Etiqyeta prueba":false }
    
                        
                    } else{
                        // console.log('sali2')
                        chat.tags={"queja":false, "atendido": false, "credito":false, "ventas":false,"Etiqyeta prueba":false }
                        
                    }
                    var ms = moment().diff(moment(moment(chat.horaUltimoMsj).toDate()))
                    var d = moment.duration(ms)
                    if(chat.senderid.substr(0,3) == '521'){
                        chat.numero = chat.senderid.substr(3,10)
                    } else {
                        chat.numero = chat.senderid
                    }
                    if(chat.espera==true){
                        this.espera++
                    } else {
                        this.inbox++
                    }                  
                    chat.diferencia=d.asMinutes()
                    // chat.nombre='saasfasfasf'
                    console.log(chat)
                    return chat
                })
                
                this.chats = respa
                this.arregloTotal = this.chats.length
                this.$bus.$emit('chatsTotal', this.arregloTotal)
                //this.$bus.$emit('espera1', this.espera)
                //this.$bus.$emit('inbox1', this.inbox)
                switch (this.etiqueta) {
                    case 1:
                        this.chats=this.chats.filter(chat=> chat.tags.queja)
                        break;
                    case 2:
                        this.chats=this.chats.filter(chat=> chat.tags.atendido)
                        break;
                    case 3:
                        this.chats=this.chats.filter(chat=> chat.tags.credito)
                        break;
                    case 4:
                        this.chats=this.chats.filter(chat=> chat.tags.ventas)
                        break;
                    default:            
                        break;
                }
                this.$bus.$emit('pacienteencola')
                this.hiloGet = setTimeout(this.get, 15000)
            }).catch(() => {
                this.hiloGet = setTimeout(this.get, 15000)
            })
        },
        IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        visto(id) {
            var visto = document.getElementById('visto' + id)
            visto.classList.remove('badge-asesor')
            visto.classList.remove('badge-paciente')
            visto.classList.remove('badge-bot')
            visto.classList.remove('badge-plataforma')
            visto.classList.remove('badge-warning')
            visto.classList.add('badge-secondary')

        },
        activo(id, senderid, sesion,chat) {
            console.log(chat)
            this.desactivar()
            var chatActivo = document.getElementById(id)
            chatActivo.classList.add('chatActive')
            this.vermensajes(senderid, chat)
            this.sendmensaje(senderid)
            this.visto(id)
            this.$bus.$emit('cuidarInput')
            this.$bus.$emit('limpiarRSV', 1)
            sessionStorage.setItem('seleccionChat', id)
            // console.log('emmit - sesion',sesion )
            this.$bus.$emit('sesionPX', sesion)
            this.$bus.$emit('sesionPX_Mensajes', sesion)
            this.$bus.$emit('paciente', this.sender_actual)
            this.$bus.$emit('areaChatActive', true); 
        },
        desactivar() {
            this.arregloTotal = this.chats.length;
            for (var i = 0; i < this.arregloTotal; i++) {
                var chatInactivo = document.getElementById(i)
                chatInactivo.classList.remove('chatActive')
            }
        },
        vermensajes(senderid, chat) {
            this.$bus.$emit('ver-mensajes', senderid, chat)
        },
        sendmensaje(senderid) {
            this.$bus.$emit('send-mensajes', senderid)
        },
        menuChat(senderid) {
            sessionStorage.removeItem('sender')
            sessionStorage.setItem('sender', senderid)
        },
        activar(id, senderid) {
            if (this.sender_actual!=null){
                var chatActivo = document.getElementById(id)
                chatActivo.classList.add('chatActive')
                this.vermensajes(senderid, chatActivo)
                this.sendmensaje(senderid)
            }else{
                this.$bus.$emit('cerrarBarra', true)
            }
            
        },
        pendientes(){
            let resp = false
            this.chats.forEach(chat => {
                if(chat.espera) resp = true
            })
            return resp
        },
        sinChats(){
            let resp = false
            this.chats.map(chat => {
                if(!chat.espera) resp = true
            })
            return resp
        },
        formatNumber(numero){
            const regex = /^[0-9]*$/;
            if(regex.test(numero) && numero.length > 6){
                return `(${numero.slice(0, 3)}) ${numero.slice(3, 6)} ${numero.slice(6, numero.length)}`
            }else{
                return numero
            }
        }
    },
    beforeDestroy() {
    this.hiloGet = 0
    delete this.hiloGet
    },
    destroyed() {
        this.get()
    }
}
</script>

