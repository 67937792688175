import { render, staticRenderFns } from "./Txt.vue?vue&type=template&id=252bc67c&scoped=true"
import script from "./Txt.vue?vue&type=script&lang=js"
export * from "./Txt.vue?vue&type=script&lang=js"
import style0 from "./Txt.vue?vue&type=style&index=0&id=252bc67c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252bc67c",
  null
  
)

export default component.exports