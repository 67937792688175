<template>
    <div class="opcChats">
        <div class="opcBtn">
            <button type="button" class="btn btnOpciones " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img id="img-tresPuntos" src="../assets/images/menu.svg" alt="" />
            </button>
        </div>
        <div class="dropdownOpciones">
            <a @click="traspaso" data-target="#traspasarChat"><img id="img-icoTraspasar" src="../assets/images/ico-traspasar.svg" alt="" /> Traspasar chat</a>
            <a @click.stop="copiar" ><img id="img-copiar" src="../assets/images/copiar.svg" alt="" />Copiar número</a>
            <a @click.stop="copiar" ><img id="img-reload" src="../assets/images/reload.svg" alt="" />Historial</a>
            <a class="disabled d-none" ><i class="icon-contacto  "></i>Enviar por email</a>
            <a @click.stop="etiquetas.queja=!etiquetas.queja, etiquetar(etiquetas)" ><img src="../assets/images/Etiquetas/naranja.svg" alt="" />  Queja</a>
            <a @click.stop="etiquetas.atendido=!etiquetas.atendido, etiquetar(etiquetas)" ><img src="../assets/images/Etiquetas/verde.svg" alt="" />  Atendido</a>
            <a @click.stop="etiquetas.credito=!etiquetas.credito, etiquetar(etiquetas)" ><img src="../assets/images/Etiquetas/morado.svg" alt="" />  Crédito</a>
            <a @click.stop="etiquetas.ventas=!etiquetas.ventas, etiquetar(etiquetas)" ><img src="../assets/images/Etiquetas/azul.svg" alt="" />  Ventas</a>

            <a v-show="network=='WA'" @click="finalizarChatSinSesion()"><img id="img-perdioSesion" src="../assets/images/perdio-sesion.svg" alt="" />Perdió sesión</a>
            
            <a class="bUltimo" @click="finalizarChat()"><img id="img-finalizar" src="../assets/images/finalizar.svg" alt="" />Finalizar chat</a>

        </div>
    </div>
                
</template>
<script>  
import asesorService from '../services/asesor'
export default {
    
    name: 'OpcionesChat',
    components: {},
    props: {
        espera: Boolean,
        tags:{},
        sender:null
    },
    data() { 
       return {
            senderid:sessionStorage.getItem('sender_actual'),
            etiquetas:this.tags,
            network: null,
        }
    },
    created(){
        this.networkActiva()
    },
    methods: {
        networkActiva(){
            this.network = asesorService.getNetwork()
        },
        abrirCerrar() {
            var info = document.getElementById("info")
            info.classList.toggle('mostrarInfo')
        },
        finalizarChat() {    
            this.$bus.$emit('actualizar-pacientes')            
        },
        finalizarChatE() {    
            this.$bus.$emit('finalizar-pacientes')            
        },
        finalizarChatSinSesion() {    
            this.$bus.$emit('finalizar-px-sinsesion')            
        },
        despedidaProgramada() {    
            this.$bus.$emit('despedida-programada')            
        },
        traspaso(){
            this.$bus.$emit('sencillo', 1)
        },
        pendiente(){  
            asesorService.pacienteEspera().then(()=>{    
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                asesorService.pacientesAtendiendo().then(()=>{    
                })
            }) 
        },
        copiar(){
            navigator.clipboard.writeText(this.sender).then(() => {

            })
            .catch(e => {
                console.error(e)
            });    
        },
        etiquetar(tag){
            console.log(tag)
            const regex = /"/gi;
            asesorService.etiquetar(this.sender, JSON.stringify(tag).replace(regex, `'`)).then(()=>{    
                this.$bus.$emit('pedirListaChats', 1)
                // this.$gtag.event('etiquetarPaciente',{
                //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                //     'event_label': ('Etiquetó con: '+ JSON.stringify(tag)),
                //     'value': 1
                // })
            }) 
        },
        informacion(){
            this.$bus.$emit('infoMostrar') 
        }
    }
}
</script>