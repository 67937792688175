<template>
<div class="entradaTexto" >
    <div class="container-fluid Txt contenedorTxt" >
        <input ref="fileUpload" id="fileUpload" type="file" accept="image/*" @change="onFileChange" hidden>
        <div role="button" @click="chooseFiles()" class="upload-image">
            <span> <font-awesome-icon icon="images" size="2x"/></span>
        </div>

        <emoji-picker @emoji="insert" :search="search" >
            <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                <img src="../assets/images/emoji.svg" alt="" /> 
            </div>
            <div v-if="sesion" slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emoji-picker">
                    <div class="emoji-picker__search">
                        <input type="text" v-model="search" v-focus placeholder="Buscar emoji" class="txtBuscar">
                    </div>
                    <div>
                        <div v-for="(emojiGroup, category, i) in emojis" :key="category">
                            <h5 v-if="i == 0">Comunes</h5><h5 v-else-if="i == 1">Caras</h5><h5 v-else-if="i == 2">Naturaleza</h5><h5 v-else-if="i == 3">Objetos</h5><h5 v-else-if="i == 4">Lugares</h5><h5 v-else>Otros</h5>
                            <div class="emojis">
                                <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">{{ emoji }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </emoji-picker>
        <!-- <input type="file" multiple accept="image/*"
            @change="handleSelects" name="images"> -->

 

        <!-- <label for="upload-file" class="css-class">
         <input type="file" multiple accept="image/*" @change="onFileChange" hidden>     
        </label> -->
        <!-- <font-awesome-icon icon="file-image" size="2x" aria-hidden="true"/>
        <input type="file" multiple accept="image/*" @change="onFileChange" hidden>      -->
        <div v-for="(image, key) in images" :key="key">
        <div id="preview"  style="margin-top:5px; margin-bottom:5px;margin-left:20px;margin-right:5px">
            <div class="img-container" >
                <img v-if="image" :src="image" height="100px" width="100px" />
                <img class="close" src="../assets/images/closeIcon.png" @click="removeImage(key)"/>
            </div>
        </div>
        </div>

        <Textarea  @keydown.enter.exact.prevent="enviarMensaje" @keydown.enter.shift.exact.prevent="nuevaLinea"  v-model="letras" v-on:input="cambiarIcono" data-min-rows='1' class="cajaTxt" type="text" placeholder="Mensaje" :autoResize="true" rows="1" ref="focus" maxlength="3999" :disabled="!sesion" :class="{'not-allowed': !sesion}" />
        <!--a v-if="!c" role="button" class="btnAdj" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip fa-rotate-45"></i>
        </a-->

        <!-- <input
          type="file"
          multiple
          accept="image/*"
          @change="onFileChange"
          /> -->

        

        <a role="button" class="btnSend" :class="{btnSendDisable: this.c}" @click="enviarMensaje" :disabled="this.c">
            <span>Enviar</span>
            <img src="../assets/images/paper-plane.svg" alt="" />
        </a>
        <!-- <div class="dropdown-menu chico">
            <a class="dropdown-item" href="#!"><i class="fas fa-robot"></i></a>
            <a class="dropdown-item" href="#!" for="mensaje">&nbsp;<i class="far fa-file-image"></i></a>
            <a class="dropdown-item" href="#!">&nbsp;<i class="fas fa-file-audio"></i></a>
            <a class="dropdown-item" href="#!"><i class="fas fa-map-marked-alt"></i></a>
        </div> -->

        <!-- <div v-for="image in images" :key="image">
            <img :src="image" class="preview">
        </div> -->
    </div>
</div>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faImages,faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
library.add(faImages, faAngleDoubleRight)
import asesorService from '../services/asesor'
import MensajeSessionHelper from '../helpers/mensajeSesion'
import Textarea from 'primevue/textarea';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'Txt',
    props: ['value'],
    components: {
        FontAwesomeIcon,
        EmojiPicker,
        Textarea
    },
    created() {
        this.$bus.$off('send-mensajes')
        this.$bus.$on('send-mensajes', senderId => this.senderid = senderId)
        this.$bus.$off('cuidarInput')
        this.$bus.$on('cuidarInput', () => {
            
            this.letras = MensajeSessionHelper.get(sessionStorage.sender)
            this.$refs.focus.$el.focus()
        })
        this.$bus.$off('sesionPX')
        this.$bus.$on('sesionPX', sesion => {
            // alert(sesion)
            this.sesion = sesion})
    },
    data() {
        return {
            c: true,
            letras: '',
            senderid: '',
            search: '',
            display: [],
            asesorId: JSON.parse(sessionStorage.getItem('sesion')),
            sesion: false,
            images: [],
            imagesRequest: []
        }
    },
    methods: {
        removeImage(idx) {
            if (idx > -1) {
                this.images.splice(idx, 1); // 2nd parameter means remove one item only
                this.imagesRequest.splice(idx, 1);
            }
            if(this.images.length<=0) this.c = true
        },
        chooseFiles() {
            if(this.sesion)
                document.getElementById("fileUpload").click()
        },
        onFileChange(e) {
            this.imagesRequest=[]
            this.images=[]
            var selectedFiles = e.target.files;
            if(this.images.length + selectedFiles.length > 10)
                return alert("maximo 10 imagenes por mensaje")
            for (let i = 0; i < selectedFiles.length; i++) {
                console.log(selectedFiles[i]);
                this.imagesRequest.push(selectedFiles[i])
                this.images.push(URL.createObjectURL(selectedFiles[i]));
            }
            this.$refs.fileUpload.value=null;

            // for (let i = 0; i < this.images.length; i++) {
            //     let reader = new FileReader();
            // reader.onload = (e) => {
            //     console.log(e)
            //         this.$refs.image[i].src = reader.result;
            //         console.log(this.$refs.image[i].src);
            //     };
            //     reader.readAsDataURL(this.images[i]);
            // }
            console.log('chose')
            console.log(this.images) 
            this.c = false
        },
        cambiarIcono() {
            console.log('cambiar')
            console.log(this.images)
            this.c = this.letras != '' ? false : true
            if(this.images.length>0) this.c = false
            if(this.letras.length>3){
                // this.$bus.$emit('respuestas_rapidas', this.letras);
            }else{
                 this.$bus.$emit('limpiarrespuestas', this.letras);
            }   
        },
        enviarMensaje() {
            if(this.images.length>0){
                const formData = new FormData();
                formData.append("imagenMessage", this.imagesRequest[0]);
                formData.append("asesorId", this.asesorId.id);
                formData.append("senderId", this.senderid);
                formData.append("caption", this.letras != '' ? this.letras : '');
                this.letras = ''
                asesorService.sendImage(this.senderid,formData).then((res) => {
                    console.log(res)
                        this.$bus.$emit('sendresp-mensajes', this.senderid)
                        this.$bus.$emit('bajarScroll', 1)
                        this.c = false
                        this.imagesRequest=[]
                        this.images=[]
                    })
            }
            else if (this.letras != '') {
                let cadena = this.letras
                this.letras = ''
                    asesorService.sendMensaje(this.senderid, cadena).then(() => {
                        this.$bus.$emit('sendresp-mensajes', this.senderid)
                        this.$bus.$emit('bajarScroll', 1)
                        this.c = false
                        // this.$gtag.event('mensajesEnviados',{
                        //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                        //     'event_label': 'Total Mensajes enviados',
                        //     'value': 1
                        // })
                    })
                
            }
            
        },
        nuevaLinea(){
            if (this.letras != null){
                this.letras = `${this.letras}\n`
            }
            
        },
        insert(emoji) {
            this.letras = this.letras!=null ? this.letras += emoji : emoji
        },
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        }
    },
    watch:{
        letras: function(newMensaje) {
            MensajeSessionHelper.set(sessionStorage.sender, newMensaje)
            
        }
    }

}
</script>

<style scoped>
.verde{
    color:aquamarine;
}
.gris{
    color: #b1c6d0;
    background-color: #00000000;
}
.regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 20rem;
    height: 12rem;
    outline: none;
}
.regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5);
}

.upload-image{
    position: relative;
    right: 2px;
    bottom: 5px;
    width: 1.5rem;
    height: 1rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.upload-image:hover {
    transform: scale(1.1);
}

.upload-image>svg {
    fill: #b1c6d0;
}


.emoji-invoker {
    position: relative;
    right: -10px;
    bottom: 2px;
    width: 3rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.emoji-invoker:hover {
    transform: scale(1.1);
}

.emoji-invoker>svg {
    fill: #b1c6d0;
}

.emoji-picker {
    position: absolute;
    bottom: 60px;
    z-index: 1;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #ccc;
    width: 265px;
    height: 20rem;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.emoji-picker::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.emoji-picker {
    -ms-overflow-style: none;
}
.emoji-picker__search {
    display: flex;
    position: sticky;
    top: 1px;
}
.emoji-picker__search>input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}

.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}

.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}

.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}

.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

</style>
