<template>
    <div class="contenedorChat">
        <MenuLateral/>
        <div id="chat" class="seccionChat">
            <navbar />
            <div class="container-fluid">
                <componente-superior />
                <!-- <componente-inferior /> -->
                <transferir-chat />
            </div>
            
        </div>
    </div>

</template>

<script>
// import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import ComponenteSuperior from '@/components/ComponenteSuperior.vue'
// import ComponenteInferior from '@/components/ComponenteInferior.vue'
import TransferirChat from '@/components/TransferirChat.vue'
import MenuLateral from '@/components/MenuLateral.vue'

export default {
    name: 'chat',
    components: {
        Navbar,
        ComponenteSuperior,
        // ComponenteInferior,
        TransferirChat,
        MenuLateral
    },
    mounted(){ 
         this.validadUsuario();
    },
    methods: {
         validadUsuario : function(){ 
             let asesor = JSON.parse(sessionStorage.getItem('sesion'))   
             if(!asesor){
                 window.location.href ='/'
             }
        }
    }
}
</script>
