<template>

<div class="filtrosMenu">
    <div class="sliceContenedor">
        <div class="slice" :class="a0 ? 'sliceActive' : ''" @click="clickTodo">
            <img src="../assets/images/chat.svg" alt="" />
            <transition name="slide">
                <span>Chats</span>
            </transition>
        </div>
        <div v-if="a0" class="active"></div>
    </div>
    <div class="sliceContenedor">
        <div class="slice" :class="a1 ? 'sliceActive' : ''" @click="clickPendiente">
            <img src="../assets/images/reloj-arena.svg" alt="" />
            <transition name="slide">
                <span>Pendientes</span>
            </transition>
        </div>
        <div v-if="a1" class="active"></div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Filtros',
    props: ['filtroOrigen'],
    data(){
        return{
            a0:true,
            a1:false,
            a2:false,
            a3:false,
            a4:false,
            chats: [],
            espera: 0,
            inbox: 0,
        }
    },
    created(){
        
    },
    methods:{
        todoF() {
            this.a0=false;
            this.a1=false;
            this.a2=false;
            this.a3=false;
            this.a4=false;
            this.a5=false;
            
        },
        clickTodo() {
            this.todoF();
            this.a0=true;
            this.$bus.$emit('inbox', false);
            this.$bus.$emit('opcChats', true);
        },
        clickPendiente() {
            this.todoF();
            this.a1=true;
            this.$bus.$emit('espera', true);
            this.$bus.$emit('opcChats', false);
        },
        clickMsn() {
            this.todoF();
            this.a3=true;
        },
        clickInsta() {
            this.todoF();
            this.a4=true;
        },
        clickHistorial() {
            this.todoF();
            this.a5=true;
        }
    }
}
</script>
