<template>
    <div>
        <p class="text-center h6">Cliente seleccionado: <br> <b>{{sender}}</b></p>
        <ul class="list-group text-dark">
            <button type="button" class="list-group-item" @click="traspaso" data-target="#traspasarChat" title="Traspasa este numero a un asesor"><i class="icon-chat-1" ></i> Traspasar</button>
            <button type="button" class="list-group-item" @click="finalizarChat"><i class="icon-ergonomicos" title="Finaliza éste chat"></i> Finalizar Chat</button>
            <!-- <button type="button" class="list-group-item" @click="despedidaProgramada" title="Pregunta al cliente si se resolvieron sus dudas, en caso contrario volverá al Inbox"><font-awesome-icon icon="user-slash" /> Cliente Atendido</button> -->
        </ul>
    </div>
</template>
<script>
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import asesorService from '../../services/asesor'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpenText, faQuestionCircle, faExclamationCircle, faPhone } from '@fortawesome/free-solid-svg-icons' 
library.add(faEnvelopeOpenText, faQuestionCircle, faExclamationCircle, faPhone)
export default {
    name:'OpcionesInbox',
    data(){
        return {
            sender:sessionStorage.getItem('sender_actual'),
            sesion:JSON.parse(sessionStorage.getItem('sesion'))
        }
    },
    components:{
        // FontAwesomeIcon
    },
    created(){
        this.$bus.$off('paciente')
        this.$bus.$on('paciente', ()=> this.sender = sessionStorage.getItem('sender_actual'))
    },
    methods: {
        finalizarChat() {    
            this.$bus.$emit('actualizar-pacientes')
        },
        finalizarChatE() {   
            this.$bus.$emit('finalizar-pacientes')
        },
        despedidaProgramada() {   
            asesorService.finalizacionProgramada(this.sender).then(() => {
                this.$bus.$emit('pedirListaChats')
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
            })
        },
        traspaso(){
            this.$bus.$emit('sencillo', 1)
        },
    }

}
</script>