<template>
    <div>
        <div class="contenedorNum">
            <div>
                <span class="tituloChats">En cola</span>
                <span v-if="chats" class="subtituloChats">Atendidos</span>
                <span v-else class="subtituloChats">Pendientes</span>
            </div>
            <div class="contenedorCantidad">
                <img id="img-usuarios" v-if="chats" src="../assets/images/usuarios.svg" alt="" />
                <img id="img-usuario" v-else src="../assets/images/usuario.svg" alt="" />
                <span v-if="chats">{{atendiendo}}</span>
                <span v-else>{{cola.cola}}</span>
                <div class="contenedorImagenes">
                    <img v-if="(chats && atendiendo >= 4) || (!chats && cola.cola >= 4)" src="../assets/images/user.png" alt="" />
                    <img v-if="(chats && atendiendo >= 3) || (!chats && cola.cola >= 3)" src="../assets/images/user.png" alt="" />
                    <img v-if="(chats && atendiendo >= 2) || (!chats && cola.cola >= 2)" src="../assets/images/user.png" alt="" />
                    <img v-if="(chats && atendiendo >= 1) || (!chats && cola.cola >= 1)" src="../assets/images/user.png" alt="" />
                    <div v-if="(chats && atendiendo > 4) || (!chats && cola.cola > 4)" class="circleImg rounded-circle">
                        <span v-if="chats">+{{atendiendo - 4}}</span>
                        <span v-else>+{{cola.cola - 4}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pedirChat">
            <button class="btn" type="button" @click="pidiendoPaciente" :disabled="this.cola.cola<0" >Pedir conversación<img src="../assets/images/pencil.svg" alt="" /></button>
        </div>
    </div>
    
<!-- <div class="col-md-4 col-lg-3 col-xx-2 px-0 haltura d-none d-lg-block d-xl-block">
    <div class="row">

        <div class="col-7 ml-2">
            <button class="btn btn-primary w-100 " type="button" @click="pidiendoPaciente" :disabled="this.cola.cola<0" >Pedir <font-awesome-icon class="ml-1"  icon="comments"/></button>
        </div>
        <div class="col-4  pb-0 mb-0">
            <p class=" pb-0 mb-0 text-right">
                {{this.cola.cola}} <font-awesome-icon  icon="users"/> en cola. <br>
                {{this.quejas}}
            </p>
            <p class=" pb-0 mb-0 text-right">
                {{this.atendiendo}} <font-awesome-icon icon="user-injured"/>
            </p>
        </div>
    </div>

</div> -->
</template>

<script>
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/index.css'
import asesorService from '../services/asesor'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUsers, faComments } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add(faUsers, faComments)
import Vue from 'vue'
Vue.use(VueToast)
import SocketIo from 'socket.io-client'

export default {
    name: 'Cola',
    components: {
        // 'font-awesome-icon': FontAwesomeIcon
    },
    methods: {
        pidiendoPaciente() {
            this.$bus.$emit('actualiza', 1);
            this.$bus.$emit('subirScrollChat', 1);
            Vue.$toast.open({
                message: 'Un momento porfavor...',
                position: 'top-left',
                type: 'default'
            });
            // this.$gtag.event('pedirPaciente',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': 'Total Pedidos',
            //     'value': 1
            // })
        },
        async pacienteencola() {
            // clearTimeout(this.hiloPacienteCola)
            // asesorService.pacientesEnCola().then(respa => {
            //     this.cola = JSON.parse(JSON.stringify(respa));
            //     this.hiloPacienteCola = setTimeout(this.pacienteencola, 15000)
            // }).catch(() => {
            //     this.hiloPacienteCola = setTimeout(this.pacienteencola, 15000)
            // })
            this.network = await asesorService.getNetwork()
            let data = {
                    query: {
                        "Tipo": "2"
                    }
                }
                console.log(this.network)
            if(this.network == 'WA'){
                // console.log('pedirCola-antes' )
                this.socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
                this.socket.on('pedirCola',  (payload) => {
                                        console.log("cola ws",payload)

                    // console.log('pedirCola-adentro',payload )
                    this.cola=payload
                })
            }
            // else if(this.network=='WAni'){
                
            //     this.socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
            //     this.socket.on('pedirColaNicaragua',  (payload) => {
            //         this.cola=payload
            //     })
            else {
                
                // this.socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
                // this.socket.on('pedirColaFb',  (payload) => {
                //     console.log("cola facebook",payload)
                //     this.cola=payload
                // })
                asesorService.pacientesEnCola().then(respa => {
                    console.log("cola facebook",respa)
                    this.cola = respa
                })
            }
            
            // let data = {
            //     query: {
            //         "Tipo": "1"
            //     }
            // }

            // var socket = SocketIo.connect('https://whatsapp-websocket-zdmkv5jsund.herokuapp.com', data)
            // socket.on('pedirCola',  (payload) => {
            //     this.cola=payload
            // })

        },
        pollData() {
            this.pacienteencola()
            // this.polling = setInterval(() => {
            // }, 15000)
        },
        nuevoPaciente() {
            Vue.$toast.open({
                message: '________ te ha transferido un paciente.',
                position: 'bottom-left',
            });
        },
    },
    data() {
        return {
            quejas: '',
            cola: '',
            atendiendo: 0,
            polling: null,
            hiloPacienteCola: null,
            network: localStorage.getItem('Network'),
            socket:null,
            chats: true
        }
    },
    mounted() {
        // this.pacienteencola()
        // this.paciesatendiendo()
    },
    created() {
        this.$bus.$off('cambioNetwork')
        this.$bus.$on('cambioNetwork', () => {
            this.socket.close()
            this.cola=''
            this.pacienteencola()
            
        })
        this.$bus.$off('chatsTotal')
        this.$bus.$on('chatsTotal', i => {
            this.atendiendo=i
        })
        this.pollData()
        this.$bus.$off('opcChats')
        this.$bus.$on('opcChats', opc=>{
            this.chats = opc
        })
    },
    beforeDestroy() {
    this.hiloPacienteCola = 0
    delete this.hiloPacienteCola
  },
  destroyed() {
    this.pacienteencola()
  }
}
</script>
