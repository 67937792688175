<template>
    <div id="menuLateral" class="contenedorMenuL">
        <div class="menuLogos">
            <a @click="dashboard" class="pointer" v-if="sessionStorageUser.rol_id<=2" title="Ir a Dashboard">
                <img src="../assets/images/logo-gris.svg" class="logoGris" alt="" />
            </a>
            <a v-else title="Logo">
                <img src="../assets/images/logo-gris.svg" class="logoGris" alt="" />
            </a>
            <filtros />
        </div>
        <div class="menuOpciones">
            <div class="sliceContenedor">
                <div class="slice modoOscuro" @click="darkMode">
                    <img id="img-darkOn" src="../assets/images/ico-off.svg" alt="" />
                    <transition name="slide">
                        <span>Modo oscuro</span>
                    </transition>
                </div> 
            </div>
            <div class="sliceContenedor">
                <div class="slice" @click="cerrarSession">
                    <img id="img-sesion" src="../assets/images/cerrar-sesion.svg" alt="" />
                    <transition name="slide">
                        <span>Cerrar sesión</span>
                    </transition>
                </div>      
            </div>
        </div>
    </div> 
</template>
<script>
    import Filtros from './Filtros.vue';
    
    export default {
        name: 'MenuLateral',
        components: {
            Filtros
        },
        data (){
            return {
                sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
                tema: JSON.parse(localStorage.getItem('tema')),
                thema:true,
            }
        },
        created(){
            if(this.ruta=='/chat'){
                if(this.tema){
                    document.documentElement.classList.toggle('dark-mode')
                    this.thema=true
                }else{
                    this.thema=false
                }
            }
        },
        methods: {
            cerrarSession() {
                sessionStorage.clear();
                window.location.href = "/";
            },
            dashboard() {
                window.location.href = '/dashboard'
            },
            darkMode(){
                document.documentElement.classList.toggle('dark-mode')
                localStorage.setItem('tema', JSON.parse(!JSON.parse(localStorage.getItem('tema'))))
            }
        }     
    }
</script>