<template>
<div id="contenedorLista" class="list-group">
<!-- <filtros /> -->
<chat :buscar="recibiendo" :etiqueta="tipoEtiqueta" />
</div>

</template>

<script>
import Chat from './Chats';
// import Filtros from './Filtros';

export default {
    name: 'EntradaChats',
    components: {
        Chat,
        // Filtros
    },
    data (){
        return {
            recibiendo: '',
            tipoEtiqueta: null
      }
    },
    props: ['buscar'],
    created() {  
        this.$bus.$off('buscar')
        this.$bus.$on('buscar', busqueda => this.recibiendo = busqueda)
        this.$bus.$off('buscarXetiqueta')
        this.$bus.$on('buscarXetiqueta', tipoTag => this.tipoEtiqueta = tipoTag)
    },
    
}
</script>
