<template>
    <div class="opcAreaChat">
        <div class="regresarContenedor" @click="regresar()">
            <a><img src="../assets/images/regresar.svg" alt="" /></a>
        </div>
    </div>
                
</template>
<script>  
export default {
    
    name: 'OpcionesAreaChat',
    components: {},
    props: {},
    data() { 
       return {}
    },
    methods: {
        regresar(){
            this.$bus.$emit('areaChatActive', false); 
        }
    }
}
</script>