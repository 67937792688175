<template>
    <div class="dropdown d-flex etiquetasContenedor" id="etiqueta">
        <div v-if="asesor.rol_id<3" class="border-red marginR" @click="monitorear" title="Traspaso general" data-target="#traspasarChat">
            <a>
                <font-awesome-icon icon="eye"/>
            </a>
        </div>
        <div class="border-red marginR" @click="traspaso" title="Traspaso general" data-target="#traspasarChat">
            <a>
                <img id="img-traspasar" src="../assets/images/traspasar.svg" alt="" />
            </a>
            <span>Traspasar</span>
        </div>
        <div class="departamentoContenedor">
            <div class="border-red" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <a>
                    <img id="img-departamento" src="../assets/images/departamento.svg" alt="" />
                </a>
                <span>Departamento</span>
            </div>    
            <div class="dropdownEtiquetas" aria-labelledby="etiqueta">
                <a class="blanco" @click="tags=0, buscarEtiqueta()"><img src="../assets/images/Etiquetas/etiqueta-todos.svg" alt="" /> Todos</a>
                <a class="tQueja" @click="tags=1, buscarEtiqueta()"><img src="../assets/images/Etiquetas/naranja.svg" alt="" /> Queja</a>
                <a class="tAtendido" @click="tags=2, buscarEtiqueta()"><img src="../assets/images/Etiquetas/verde.svg" alt="" /> Atendido</a>
                <a class="tConsulta" @click="tags=3, buscarEtiqueta()"><img src="../assets/images/Etiquetas/morado.svg" alt="" /> Crédito</a>
                <a class="tRH" @click="tags=4, buscarEtiqueta()"><img src="../assets/images/Etiquetas/azul.svg" alt="" />Ventas </a>
            </div>
        </div>
    </div>
</template>
    
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faEye)
    export default {
        name: 'EtiquetasChat',
        components: { 'font-awesome-icon': FontAwesomeIcon },
        data() {
            return {
                tags:0,
                asesor: JSON.parse(sessionStorage.getItem('sesion')),
                buscador: false
            }
        },
        methods: {
            traspaso(){
                this.$bus.$emit('multiple', 1);
            },
            monitorear(){
                this.$bus.$emit('monitorear', 4);
            },
            buscarEtiqueta(){
                this.$bus.$emit('buscarXetiqueta', this.tags); 
                this.$bus.$emit('pedirListaChats', 1); 
            },
        }    
    }
</script>
    