<template>
<div class="row componenteSuperior">
    <div class="elegirChat" :class="areaChat ? '' : 'activeArea'">
        <cola />
        <etiquetas-chats />
        <!-- <buscar /> -->
        <entrada-chats />
    </div>
    <div class="areaChat" :class="areaChat ? 'activeArea' : ''">
        <div id="wrapper" class=" " v-bind:class="{toggled: isActive}">
            <opciones-area-chat />
            <info-paciente />
            <herramientas-mensajes v-on:emitToggled="cerrarBarra" v-on:Toggled="abrir" />
            <mensajes/>
            <txt/>
        </div>

    </div>
    
</div>
</template>

<script>
// import Buscar from './Buscar.vue'
import EntradaChats from './EntradaChats.vue'
import Mensajes from './Mensajes.vue'
import HerramientasMensajes from './HerramientasMensajes.vue'
import InfoPaciente from './InfoPaciente.vue'
import EtiquetasChats from './EtiquetasChats.vue'
import Cola from './Cola.vue'
import Txt from './Txt.vue'
import OpcionesAreaChat from './OpcionesAreaChat.vue'
export default {
    name: 'ComponenteSuperior',
    components: {
        // Buscar,
        EntradaChats,
        Mensajes,
        HerramientasMensajes,
        InfoPaciente,
        EtiquetasChats,
        Cola,
        Txt,
        OpcionesAreaChat 
    },
    data() {
        return {
            isActive: false,
            inbox: 0,
            espera: 0,
            chats: true,
            areaChat: false
        }
    },
    props: ['toggledSidebar',
        'filtroOrigen'
    ],
    created() {
        this.$bus.$off('bajarScroll')
        this.$bus.$on('bajarScroll', () => {
            var chatBox;
            chatBox = document.querySelector('.areaChat')
            // cuando exista un nuevo mensaje
            setTimeout(chatBox.scrollTop = chatBox.scrollHeight, 800);

        })

        this.$bus.$off('subirScrollChat')
        this.$bus.$on('subirScrollChat', () => {
            var Scroll
            Scroll = document.querySelector('.elegirChat')
            // cuando exista una nueva conversacion
            Scroll.scrollTop = 0;
        })
        this.$bus.$off('cerrarBarra')
        this.$bus.$on('cerrarBarra', () => {
            this.isActive = false
        })  
        this.$bus.$off('areaChatActive')
        this.$bus.$on('areaChatActive', a => {
            console.log('active', a)
            this.areaChat = a
        })      
    },
    methods: {
        abrir: function () {
            this.isActive = true;
        },
        cerrarBarra(){
            this.isActive = false;
        }
    }

}
</script>
